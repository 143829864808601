<afc-page-layout [normalView]="true">
  <ng-template afcDesktopTemplate>
    <afc-breadcrumb ngClass="funeral-cover" [currentPage]="breadcrumbCurrentPage" [pages]="breadcrumbPages">
    </afc-breadcrumb>
    <div class="page-container-grid">
      <!-- Overview -->
      <afc-section class="overview-section grid-item">
        <afc-section-header title="Cover overview"></afc-section-header>
        <afc-content-container>
          <div class="overview">
            <div class="name">Unapproved Funeral Cover</div>
          </div>
        </afc-content-container>
      </afc-section>

      <!-- Beneficiaries -->
      <afc-section class="beneficiaries-section grid-item">
        <afc-section-header title="Beneficiaries">
          <div icon class="circle-icon-outer">
            <afc-svg-button src="assets/icons/switch.svg" (clickBtn)="(null)"></afc-svg-button>
          </div>
          <ng-container *ngIf="nobService.beneficiaryDetails$ | async as details"> 
            <ng-container
              *ngIf="
                nobService.getMembership(details?.membership, NominationTypeId.Funeral, null)?.beneficiaries?.length ||
                  0 > 0;
                then replaceBtnWeb;
                else addBtn
              "
            >
            </ng-container>
            <afc-tooltip
              *ngIf="details?.membership?.length || 0 > 0"
              ngClass="funeral-cover__info-btn-tooltip"
              text="You have added the maximum number of beneficiaries.
              To add new, you must replace an existing one."
            >
              <img src="assets/icons/info.svg" />
            </afc-tooltip>
          </ng-container>
        </afc-section-header>
        <ng-container *ngIf="nobService.beneficiaryDetails$ | async as details; else checkError">
          <ng-container
            *ngIf="
              nobService.getMembership(details?.membership, NominationTypeId.Funeral, null)?.beneficiaries
                ?.length || 0 > 0;
              else noContent
            "
          >
            <afc-content-container>
              <afc-fc-beneficiaries
                ngClass="beneficiaries-table"
                (onEditAction)="openModal(true, $event)"
                [data]="details"
              >
              </afc-fc-beneficiaries>
            </afc-content-container>
          </ng-container>
        </ng-container>
      </afc-section>
    </div>
  </ng-template>

  <ng-template afcMobileTemplate>
    <afc-breadcrumb ngClass="funeral-cover" [currentPage]="breadcrumbCurrentPage" [pages]="breadcrumbPages">
    </afc-breadcrumb>
    <afc-expandable-wrapper>
      <afc-section-header title="Cover Overview" [isStatic]="false">
        <afc-expandable-arrow></afc-expandable-arrow>
      </afc-section-header>
      <afc-expandable-container [isExpanded]="true">
        <div class="mobile-wrapper">
          <div class="overview-section">
            <div class="overview">
              <div class="name">Unapproved Funeral Cover</div>
            </div>
          </div>

          <div class="beneficiaries-table-title-mobile">
            <span>Beneficiaries</span>
            <div class="beneficiaries-table-title-mobile__btn-container">
              <ng-container *ngIf="nobService.beneficiaryDetails$ | async as details">
                <ng-container
                  *ngIf="
                    nobService.getMembership(details?.membership, NominationTypeId.Funeral, null)?.beneficiaries
                      ?.length || 0 > 0;
                    then replaceBtnMobile;
                    else addBtn
                  "
                >
                </ng-container>
                <afc-tooltip
                  *ngIf="details?.membership?.length || 0 > 0"
                  ngClass="funeral-cover__info-btn-tooltip"
                  text="You have added the maximum number of beneficiaries.
                  To add new, you must replace an existing one."
                >
                  <img src="assets/icons/info.svg" />
                </afc-tooltip>
              </ng-container>
            </div>
          </div>
          <ng-container *ngIf="nobService.beneficiaryDetails$ | async as details; else checkError">
            <ng-container
              *ngIf="
                nobService.getMembership(details?.membership, NominationTypeId.Funeral, null)?.beneficiaries
                  ?.length || 0 > 0;
                else noContent
              "
            >
              <afc-fc-beneficiaries
                ngClass="beneficiaries-table"
                (onEditAction)="openModal(true, $event)"
                [data]="details"
              >
              </afc-fc-beneficiaries>
            </ng-container>
          </ng-container>
        </div>
      </afc-expandable-container>
    </afc-expandable-wrapper>
  </ng-template>
</afc-page-layout>

<afc-modal
  *ngIf="isModalOpen"
  header="Nomination of beneficiary - unapproved funeral benefit"
  (onClose)="closeModal()"
  [width]="850"
  [isOpen]="isModalOpen"
  [disableOutsideClickClose]="true"
>
  <afc-fc-beneficiary-modal
    #beneficiaryModal
    [isInEditMode]="inEditMode"
    [beneficiaryId]="beneficiaryId"
    [isReplaceJourney]="isReplaceJourney"
  >
  </afc-fc-beneficiary-modal>
</afc-modal>

<ng-template #addBtn>
  <afc-button (clickBtn)="openModal()" title="Add" size="small" variant="filled"></afc-button>
</ng-template>

<ng-template #replaceBtnWeb>
  <afc-button (clickBtn)="openReplaceModal()" title="Replace" size="small" variant="filled"></afc-button>
  <afc-button
    *ngIf="this.activeDownloadSection != ActiveDownloadSectionType.Loading"
    (clickBtn)="downloadPdf()"
    size="small"
    title="Download PDF"
  ></afc-button>
  <afc-button *ngIf="this.activeDownloadSection == ActiveDownloadSectionType.Loading" size="small" class="loading">
    <afc-loading-ellipsis loading-spinner [size]="10" [spacing]="4"></afc-loading-ellipsis>
  </afc-button>
</ng-template>

<ng-template #replaceBtnMobile>
  <afc-button (clickBtn)="openReplaceModal()" title="Replace" size="small" variant="filled"></afc-button>
  <afc-svg-button
    *ngIf="activeDownloadSection != ActiveDownloadSectionType.Loading"
    (clickBtn)="downloadPdf()"
    size="large"
    src="assets/icons/download.svg"
  ></afc-svg-button>
  <afc-button *ngIf="activeDownloadSection == ActiveDownloadSectionType.Loading" class="loading circle">
    <afc-loading-ellipsis loading-spinner [size]="6" [spacing]="2"></afc-loading-ellipsis>
  </afc-button>
</ng-template>

<ng-template #loading>
  <afc-content-container class="justify-center" ngClass="no-shadow">
    <afc-loading-spinner></afc-loading-spinner>
  </afc-content-container>
</ng-template>

<ng-template #checkError>
  <ng-container *ngIf="nobService.beneficiaryDetailsError$ | async; else loading">
    <div class="error-container">
      <ng-container>
        <afc-error
          size="large"
          src="assets/icons/error.svg"
          title="Something went wrong"
          message="Something went wrong fetching your beneficiaries. Please try logging in later."
        ></afc-error>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #noContent>
  <afc-no-content
    class="no-content"
    ngClass="beneficiaries"
    title="No beneficiaries loaded yet"
    message="If no beneficiary is appointed, the benefit will be paid to your estate as required by law."
    src="assets/icons/group.svg"
  >
  </afc-no-content>
</ng-template>
