<ng-container *ngIf="!(profileService.isLoadingProfile$ | async) || !isAuthenticated || disableDataServicesCalls then app; else loading"></ng-container>
<ng-container *ngIf="showExpiredSessionModal" [ngTemplateOutlet]="expiredSessionModal"></ng-container>

<ng-template #app>
  <div class="app-container">
    <lib-af-connect-header
      *ngIf="isAuthenticated"
      [ngClass]="isAuthenticated === true ? 'authenticated' : ''"
      [isLogoHidden]="!isAuthenticated"
      [userType]="profileService.getUserType()"
      [hasSwitchIndicator]="false"
      (resetPassword)="resetPassword()"
      (contactAdviser)="linkToContactAdviser()"
      (logout)="logout()"
      (switchUserType)="switchUserType($event)"
    >
    </lib-af-connect-header>
    <div class="main">
      <router-outlet></router-outlet>
    </div>
    <lib-af-connect-footer [isFooterContentHidden]="!isAuthenticated" [isBottomBannerHidden]="!isAuthenticated"></lib-af-connect-footer>
  </div>
</ng-template>

<ng-template #expiredSessionModal>
  <afc-idle-state-modal
    [showModal]="showExpiredSessionModal"
    [timerDurationInMinutes]="idleModalDurationInMinutes"
    (logoutBtnEvent)="logout()"
    (continueBtnEvent)="continueUserSession()"
    (timerElapsedEvent)="logout()"
  >
  </afc-idle-state-modal>
</ng-template>

<afc-toaster-message class="notification"
  *ngIf="notificationInfo.notificationType != NotificationType.Default"
  @flyInOut size="large" closeButtonSize="small"
  src="assets/icons/{{ notificationInfo.notificationType == NotificationType.Success ? 'success' : 'error' }}_icon.svg"
  [type]="notificationInfo.notificationType"
  [title]="notificationInfo.title"
  [message]="notificationInfo.message ? notificationInfo.message : ''"
  (onClose)="closeNotification()">
</afc-toaster-message>

<ng-template #loading>
  <afc-content-container class="justify-center" ngClass="no-shadow">
    <afc-loading-spinner></afc-loading-spinner>
  </afc-content-container>
</ng-template>
